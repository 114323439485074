import campusData from '@/src/router/views/campuses/campuses.json'
import GRAPH_CONSTANTS from '@src/constants/graph-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export const rightBarStats = (campusCounts) => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
      categories: [
        {
          text: campusData.text,
          value: campusCounts || GENERAL_CONSTANTS.EMPTY_RECORD,
          tooltipDescription: campusData.tooltipDescription,
        },
      ],
    },
  ]
  return stats
}
