<template>
  <ValidationObserver @submit="addCampus">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-if="edit">
          <span v-i18n="dashboard">Edit Campus</span>
        </span>
        <span v-else v-i18n="dashboard">Add Campus</span>
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiInput
            v-model="campusFormData.title"
            type="text"
            name="Title"
            title="Title"
            label="Title"
            placeholder="Title"
            class="flex-1"
            rules="required|alpha_spaces"
          />
          <UiInput
            v-model="campusFormData.location"
            type="text"
            name="location"
            title="LOCATION"
            label="location"
            placeholder="Location"
            class="flex-1"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiInput
            v-model="campusFormData.city"
            type="text"
            name="City"
            title="CITY"
            label="location"
            placeholder="City"
            class="flex-1"
          />

          <UiInput
            v-model="campusFormData.description"
            type="text"
            name="Description"
            title="Description"
            label="location"
            placeholder="Description"
            class="flex-1"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <PhoneNumber
            v-model="campusFormData.contact_no"
            title="CONTACT_NUMBER"
            rules="validNumber"
            class="flex-1"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              <span v-if="edit">Update</span>
              <span v-else>Save</span>
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

import { mapState, mapActions } from 'vuex'
export default {
  components: {
    UiModalContainer,
    UiInput,
    Loader,
    ValidationObserver,
    PhoneNumber,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    edit: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      dashboard: 'dashboard',
      campusFormData: {
        title: '',
        description: '',
        location: '',
        image: 'img',
        city: '',
        contact_no: '',
      },
    }
  },
  computed: {
    ...mapState('layout', ['isLoading']),
    ...mapState('instituteCount', ['instituteCount']),
    modalOpen() {
      return this.modal
    },
  },
  created() {
    if (this.edit) this.setCampusData()
  },
  methods: {
    ...mapActions('campuses', ['createCampus']),
    ...mapActions('layout', ['getCampusList']),
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
        case 'cancel':
          // this.$emit('toggle')
          break
        case 'save':
          break
      }
    },
    async addCampus() {
      try {
        this.$store.commit('layout/IS_LOADING', true)

        let data = {
          campus: this.campusFormData,
        }
        if (this.edit) data.id = this.edit.id
        await this.createCampus(data).then((res) => {
          this.$store.commit('layout/IS_LOADING', false)
          this.$store.commit('toast/NEW', {
            type: 'success',
            message: this.edit
              ? `${res?.data?.title} campus successfully updated `
              : `${res?.data?.title} campus successfully created`,
          })

          this.getCampusList()
          this.$store.commit('instituteCount/INCREMENT_INSTITUTE_COUNT', 'campuses_count')
        })
        this.$emit('toggle', true)
      } catch (err) {
        this.$store.commit('layout/IS_LOADING', false)
      }
    },
    setCampusData() {
      this.campusFormData.title = this.edit.title
      this.campusFormData.description = this.edit.description
      this.campusFormData.location = this.edit.location
      this.campusFormData.city = this.edit.city
      this.campusFormData.contact_no = this.edit.contact_no
    },
  },
}
</script>
