<template>
  <div>
    <CampusFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="setFilterData"
    />
    <template v-if="isLoading">
      <Loader class="mt-20 mb-40" :content="true" />
    </template>
    <div v-else-if="!isLoading && isEmpty(campusList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
            :key="head.id"
          >
            {{ $t(`dashboard.${head.dataTableName}`) }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(campus, index) in campusList" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'NAME', ['NAME'])">
            {{ campus.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'LOCATION', ['LOCATION'])">
            {{ campus.location || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'CITY', ['CITY'])">
            {{ campus.city || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Description')">
            {{ campus.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Contact Number')">
            <DisplayPhoneNumber :phone="campus.contact_no" />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :current-user="campus"
              :idx="index"
              :action-list="actionList"
              @action="campusActions"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :key="`Pagination${forceRender}`"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(campusCounts, filteredRecordLimit)"
      @filterRecord="fetchCampusesList"
    />
    <CampusModal
      v-if="showModal"
      :modal="showModal"
      :edit="currentCampus"
      @toggle="toggleCampusModal"
    ></CampusModal>
    <UIConfirmationModal
      v-if="confirmation"
      heading="DEACTIVATE_CAMPUS"
      message="Are you sure you want to deactivate "
      button-text="Deactivate"
      :modal="confirmation"
      :name="currentCampus.title"
      remaining-message="campus ?"
      @cancel="toggleConfirmationModal"
      @confirm="deactivateCampusEntity"
    />
  </div>
</template>

<script>
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import CampusModal from '@/src/router/views/admin/modals/CampusModal.vue'
import CampusFilter from '@src/router/views/campuses/CampusViewFilter.vue'
import TableAction from '@/src/components/TableAction.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import generalMixin from '@src/mixins/general-mixins.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { objectDeepCopy, showColumnInDataTable } from '@/src/utils/generalUtil.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import DisplayPhoneNumber from '@src/components/UiElements/PhoneDisplay.vue'
import campusesData from '@/src/router/views/campuses/campuses.json'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import { rightBarStats } from '@/src/router/views/campuses/campuses.util.js'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import fileMixins from '@/src/mixins/file-mixins'
import isEmpty from 'lodash/isEmpty'
import { mapState, mapActions } from 'vuex'
import NoRecordFound from '@components/BaseComponent/NoRecordFound.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    DisplayPhoneNumber,
    CampusModal,
    TableAction,
    Loader,
    CampusFilter,
    Pagination,
    UIConfirmationModal,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [scrollMixin, fileMixins, generalMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      isEmpty,
      isLoading: false,
      dashboard: 'dashboard',
      filtersData: {},
      errorMsg: false,
      options: ['Select'],
      showPagination: false,
      campusCounts: null,
      forceRender: 0,
      skipForFilterColumns: campusesData.skipForFilterColumns,
      defaultSelectedColumns: campusesData.defaultSelectedColumns,
      filteredRecordLimit: GENERAL_CONSTANTS.RECORD_LIMIT,
      tableHeaders: campusesData.tableHeaders,
      campusList: null,
      currentCampus: null,
      confirmation: false,
      actionList: campusesData.actionList,
    }
  },
  page: campusesData.page,
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      campuses: (state) => state.layout.campuses,
    }),
  },
  watch: {
    campusList: {
      handler() {
        this.setRightbar()
      },
    },
  },
  mounted() {
    this.fetchCampusesList()
  },
  methods: {
    showColumnInDataTable,
    setFilterData(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.fetchCampusesList()
    },
    setRightbar() {
      this.setRightbarContent({
        header: {
          title: 'Campuses',
          buttons: [
            {
              title: 'CV_RB_BT',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal' },
            },
          ],
        },
        stats: rightBarStats(this.campusCounts),
      })
    },
    toggleConfirmationModal() {
      this.confirmation = !this.confirmation
      if (!this.confirmation) this.currentCampus = null
    },
    /**
     * Perform action on campuses
     * @param {String} action - WHich action to perform
     * @param {Object} current - Current selected object
     */
    campusActions(action, current) {
      this.currentCampus = current
      switch (action) {
        case 'Edit':
          this.toggleCampusEditModal()
          break
        case 'Deactivate':
          this.toggleConfirmationModal()
          break
      }
    },
    deactivateCampusEntity() {
      this.deactivate(this.currentCampus.id, this.currentCampus.title)
    },
    /**
     * Toggle edit campus modal
     */
    toggleCampusEditModal() {
      this.$store.dispatch('layout/setShowModal', true)
    },
    fetchCampusesList(range) {
      this.isLoading = true
      const paginationLimit = paginationRangeHandler(range)
      let payload = {
        ...paginationLimit,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getCampusList(payload)
        .then((respose) => {
          this.campusList = respose?.data?.records
          this.campusCounts = respose.data.meta.total_records
          this.$store.commit('layout/GET_ALL_CAMPUS', this.campuses)
          this.showPagination = this.campusCounts > GENERAL_CONSTANTS.RECORD_LIMIT
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /**
     * Toggle Add new Campus or edit existing one modal
     * @param {Boolean} payload - Checks its complete its job successfully
     */
    toggleCampusModal(payload) {
      this.$store.dispatch('layout/setShowModal', false)
      this.currentCampus = null
      if (payload) {
        this.fetchCampusesList()
        this.forceRender++
      }
    },
    deactivate(id, title) {
      const payload = {
        id,
        title,
      }
      this.deactivateCampus(payload)
        .then(() => {
          this.fetchCampusesList()
          this.confirmation = false
          this.forceRender++
        })
        .finally(() => {
          this.currentCampus = null
        })
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('campuses', ['getCampusList', 'deactivateCampus']),
  },
}
</script>
